import Debug from 'debug';
import React, { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';

import { Box, Button, Grid, FormControl, FormControlLabel, FormLabel, Icon, Paper, Radio, RadioGroup, Switch, Typography } from '@mui/material';

const debug = Debug('ezsso:admin:Welcome');

import { AppContext } from './AppContext';

export default function () {
	debug('Welcome');

	const {
		session, setSession
	} = useContext(AppContext);

	const handleLoginClick = () => setSession(Object.assign({}, session, {login: true}));

	const markdown = `
The [last mile](https://en.wikipedia.org/wiki/Last_mile_(telecommunications)) delivery for the [authorization](https://en.wikipedia.org/wiki/Authorization) [at scale](https://en.wikipedia.org/wiki/Scalability) could be painful.  

But it does not need to be.  

Take that pain out of your applications.  

Literally.  

<span style='color:red'>Do not [reimplement](https://en.wikipedia.org/wiki/Code_reuse) the same authorization logic across different technologies.</span>  

<span style='color:green'>Do delegate authorization from different technologies to the same [service](https://en.wikipedia.org/wiki/Software_as_a_service).</span>  

Benefits: [decoupled](https://en.wikipedia.org/wiki/Unix_philosophy#Do_One_Thing_and_Do_It_Well) [problem domains](https://en.wikipedia.org/wiki/Domain_(software_engineering)), faster [release cycles](https://en.wikipedia.org/wiki/Software_release_life_cycle), smaller footprint, faster runtimes, lower cost.

Be that [reverse proxies](https://en.wikipedia.org/wiki/Reverse_proxy) like [Envoy](https://en.wikipedia.org/wiki/Cloud_Native_Computing_Foundation#Envoy) and [Nginx](https://en.wikipedia.org/wiki/Nginx); [CDNs](https://en.wikipedia.org/wiki/Content_delivery_network) like [Akamai](https://en.wikipedia.org/wiki/Akamai_Technologies) and [Cloudfront](https://en.wikipedia.org/wiki/Amazon_CloudFront); [languages](https://en.wikipedia.org/wiki/Programming_language) like [Java](https://en.wikipedia.org/wiki/Java_(programming_language)), [JavaScript](https://en.wikipedia.org/wiki/JavaScript), and [Python](https://en.wikipedia.org/wiki/Python_(programming_language)) - all these, and many other technologies, already have what it takes.  

`;

	return (
		<>
			<ReactMarkdown linkTarget='_blank' children={markdown} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className='Welcome' />
			<br/>
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
			>
				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
					<Button variant='outlined' onClick={handleLoginClick}>Try it.</Button>
				</Typography>
			</Box>
		</>
	);
}

