import Debug from 'debug';
import React, { useContext, useState, useEffect } from 'react';

import { Box, Button, Grid, FormControl, FormControlLabel, FormLabel, Icon, Paper, Radio, RadioGroup, Switch } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ArrowForwardOutlined as ArrowForwardOutlinedIcon } from '@mui/icons-material';

import EZSSOAdmin from '@ezsso/admin';

import { AppContext } from './AppContext';
import Loader from './Loader';
import AuthN from './AuthN';

export default function () {
	const {
		session, setSession,
		ezssoAdmin, setEzssoAdmin
	} = useContext(AppContext);

	const [ authNs, setAuthNs ] = useState(null);

	const debug = Debug('ezsso:admin:Admin:'+session.email);

	debug('Admin');

	useEffect(() => {
		setEzssoAdmin(new EZSSOAdmin({
			access_token: () => session.access_token
		}));
	}, []);

	useEffect(() => {
		if(!ezssoAdmin) {
			return;
		}
		(async () => {
			setAuthNs(await ezssoAdmin.authn.ls());
		})();
	}, [ezssoAdmin]);

	if(!authNs) {
		return <Loader />;
	}

	const elements = authNs.map((authN, i) => <AuthN key={i} authN={authN} />);
	if(elements.length == 0) {
		elements.push(<AuthN key='0' />);
	}

	return  <Box>
                <Button variant='text'>Add authorizer</Button>
                <Button variant='text'>Import authorizers</Button>
                <Button variant='text'>Export authorizers</Button>
				<Box>{elements}</Box>
            </Box>;

}

