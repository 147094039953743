import Debug from 'debug';
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
	AppBar, Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Tooltip, Typography
} from '@mui/material';

import {
	Add as AddIcon,
	Menu as MenuIcon,
	Visibility as VisibilityIcon
} from '@mui/icons-material';

import { AppContext } from './AppContext';
import Loader from './Loader';

const debug = Debug('ezsso:admin:OidcIdp');

export default function (props) {
	const {
		session, setSession,
		ezssoAdmin, setEzssoAdmin
	} = useContext(AppContext);

	const [ id, setId ] = useState(props.idp?.id);

	const [ issuer, setIssuer ] = useState(props.idp?.issuer);
	const [ issuerChanged, setIssuerChanged ] = useState(false);

	const [ clientId, setClientId ] = useState(props.idp?.client_id);
	const [ clientIdChanged, setClientIdChanged ] = useState(false);

	const [ clientSecret, setClientSecret ] = useState(props.idp?.client_secret);
	const [ clientSecretChanged, setClientSecretChanged ] = useState(false);
	const [ showClientSecret, setShowClientSecret ] = useState(false);

	const [ scopesSupported, setScopesSupported ] = useState([]);
	const [ scopesSupportedChanged, setScopesSupportedChanged ] = useState(false);

	const [ shouldStore, setShouldStore ] = useState(false);

	const [ issuerInfo, setIssuerInfo ] = useState(null);

	const [ idpAdmin, setIdpAdmin ] = useState(null);

	const getIssuer = async (issuer) => {
		if(!issuer) {
			return;
		}
		return await fetch(issuer+'/.well-known/openid-configuration')
		.then(response => response.json())
		.then(issuerInfo => {
			debug('getIssuer', 'issuerInfo', issuerInfo);
			setIssuerInfo(issuerInfo);
			setIssuer(issuerInfo.issuer);
			setScopesSupported(issuerInfo.scopes_supported);
		});
	};

	const handleIssuerChange = async (event) => {
		setIssuerChanged(true);
	};

	const handleIssuerBlur = async (event) => {
		if(!issuerChanged) {
			return;
		}
		await getIssuer(event.target.value);
		setIssuerChanged(false);
		setShouldStore(true);
	};

	const handleClientIdChange = async (event) => {
		setClientIdChanged(true);
	};

	const handleClientIdBlur = async (event) => {
		if(!clientIdChanged) {
			return;
		}
		setClientId(event.target.value);
		setClientIdChanged(false);
		setShouldStore(true);
	};

	const handleClientSecretChange = async (event) => {
		setClientSecretChanged(true);
	};

	const handleClientSecretBlur = async (event) => {
		if(!clientSecretChanged) {
			return;
		}
		setClientSecret(event.target.value);
		setClientSecretChanged(false);
		setShouldStore(true);
	};

	const handleScopeChange = async (event) => {
		debug('handleScopeChange', event);
		const scope = event.target.id.replace(/^scope:[^:]+:[^:]+:/,'');
		if(event.target.checked) {
			debug('handleScopeChange', 'checked', scope);
			setScopesSupported(scopesSupported.concat([scope]));
		} else {
			debug('handleScopeChange', 'unchecked', scope, scopesSupported.filter( _scope => _scope != scope ));
			setScopesSupported(scopesSupported.filter( _scope => _scope != scope ));
		}
		debug('handleScopeChange', scopesSupported);
		setShouldStore(true);
	};

	const store = async () => {
		const idp = Object.assign({}, {
			id,
			issuer,
			client_id: clientId,
			client_secret: clientSecret,
			scopes_supported: scopesSupported
		});
		debug('store', idp);

		let data = null;

		if(id) {
			data = await idpAdmin.set(id, idp);
		} else {
			data = await idpAdmin.new(idp);
		}

		debug('store', data);
		setId(data.id);
		setIssuer(data.issuer);
		setClientId(data.client_id);
		setClientSecret(data.client_secret);
		setScopesSupported(data.scopes_supported);
	};

	useEffect(() => {
		debug('useEffect', 'mounted');
		getIssuer(issuer);
	}, []);

	useEffect(() => {
		if(!ezssoAdmin) {
			return;
		}
		(async () => {
			setIdpAdmin(await ezssoAdmin.authn.idp(props.authN.id));
		})();
	}, [ezssoAdmin]);

	useEffect(() => {
		if(!shouldStore) {
			return;
		}
		debug('useEffect', 'changed');
		store();
		setShouldStore(false);
	}, [shouldStore]);

	const scopes = [];
	if(issuerInfo?.scopes_supported) {
		issuerInfo.scopes_supported.forEach((scope, i) => {
			scopes.push(
				<FormControlLabel key={i} control={<Checkbox id={'scope:'+props.authN.id+':'+id+':'+scope} defaultChecked={scopesSupported.includes(scope)} onChange={handleScopeChange} />} label={scope} />
			);
		});
	}

	scopes.push(<AddIcon />);

	if(!idpAdmin) {
		return <Loader />;
	}

	return (
		<Box>
			<form>
			<TextField
				id={'issuer:'+props.authN.id+':'+id}
				label='Issuer'
				size='small'
				inputProps={{ style: { width: '350px', textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={issuer || ''}
				autoComplete='issuer'
				onChange={handleIssuerChange}
				onBlur={handleIssuerBlur}
			/>
			<TextField
				id={'client_id:'+props.authN.id+':'+id}
				label='Client Id'
				size='small'
				inputProps={{ style: { width: '350px', textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={clientId || ''}
				autoComplete='client_id'
				onChange={handleClientIdChange}
				onBlur={handleClientIdBlur}
			/>
			<TextField
				id={'client_secret:'+props.authN.id+':'+id}
				label='Client Secret'
				size='small'
				inputProps={{ style: { width: '350px', textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={clientSecret || ''}
				type={showClientSecret ? 'text' : 'password'}
				autoComplete='client_secret'
				onChange={handleClientSecretChange}
				onBlur={handleClientSecretBlur}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<Tooltip title={(showClientSecret?'Hide':'Show')+' client secret'}>
								<VisibilityIcon onClick={() => setShowClientSecret(!showClientSecret)} />
							</Tooltip>
						</InputAdornment>
					)
				}}
			/>
			<Box>
				{scopes}
			</Box>
			</form>
		</Box>
	);
}

