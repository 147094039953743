import Debug from 'debug';
import React, { useContext, useState, useEffect, useRef } from 'react';

import { AppBar, Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Menu as MenuIcon, Visibility } from '@mui/icons-material';

import { AppContext } from './AppContext';

const debug = Debug('ezsso:admin:AppInfoEndpoints');

export default function (props) {
	const { session, setSession } = useContext(AppContext);

	const [ endpoint, setEndpoint ] = useState(props.endpoint);
	const [ position, setPosition ] = useState(props.position);

	const [ endpointChanged, setEndpointChanged ] = useState(false);

	const [ shouldStore, setShouldStore ] = useState(false);

	const handleEndpointChange = async (event) => {
		setEndpoint(event.target.value);
		setEndpointChanged(true);
	};

	const handleEndpointBlur = async (event) => {
		if(!endpointChanged) {
			return;
		}
		setEndpointChanged(false);
		setShouldStore(true);
	};

	const store = async () => {
		debug('store >', position, endpoint);
		let url = `/api/authN/${props.authN.id}/appinfo_endpoints`;
		if(position != undefined) {
			url += `/${position}`;
		}
		return fetch(url, {
						method: 'POST',
						credentials: 'include',
						headers: { 'Content-type': 'application/json' },
						body: JSON.stringify({endpoint})
		})
		.then(response => response.json())
		.then(data => {
			debug('store <', data);
			setPosition(data.position);
			setEndpoint(data.endpoint);
		});
	};

	useEffect(() => {
		if(!shouldStore) {
			return;
		}
		debug('useEffect', 'changed');
		store();
		setShouldStore(false);
	}, [shouldStore]);

	return (
		<Box>
			<TextField
				label='Endpoint'
				size='small'
				inputProps={{ style: { width: '350px', textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={endpoint || ''}
				autoComplete='appinfo_endpoint'
				onChange={handleEndpointChange}
				onBlur={handleEndpointBlur}
			/>
		</Box>
	);
}

