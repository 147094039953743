import Debug from 'debug';
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
	AppBar,
	Avatar,
	Box,
	Button,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Pagination,
	Select,
	TextField,
	Toolbar,
	Tooltip,
	Typography
} from '@mui/material';

import {
	Add as AddIcon,
	DeleteForever as DeleteForeverIcon,
	Menu as MenuIcon
} from '@mui/icons-material';

import ReactJson from 'react-json-view';

import { AppContext } from './AppContext';

import AuthZ from './AuthZ';

const debug = Debug('ezsso:admin:AuthN');

export default function (props) {
	const { session, setSession } = useContext(AppContext);

	const [ authN, setAuthN ] = useState(props.authN);
	const [ authZs, setAuthZs ] = useState(props.authZs);
	const [ authZIndex, setAuthZIndex ] = useState(0);
	const [ showAuthZContext, setShowAuthZContext ] = useState(false);

	const sampleAuthZContext = () => {
		return {
			auth: { id: authN },
			app: { resources: [ 'resource1' ] },
			req: {
				headers: {
					'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36',
					'content-type': 'application/json',
					accept: '*/*',
					origin: 'https://hostname:443',
					referer: 'https://hostname:443',
					cookie: 'ezsso_sid=abcd-efab-cdef-abcd'
				},
				method: 'POST',
				url: {
					href: 'https://hostname:443/api/resources/resource1/subresources/subresource1',
					origin: 'https://hostname:443',
					protocol: 'https:',
					username: '',
					password: '',
					host: 'hostname:443',
					hostname: 'hostname',
					port: '443',
					pathname: '/api/resources/resource1/subresources/subresource1',
					search: '',
					hash: '',
					pathsegs: [ 'api', 'resources', 'resource1', 'subresources', 'subresource1' ]
				}
			},
			user: {
				email: 'user@domain',
				groups: [ 'group:1', 'group:2' ]
			}
		};
	};

	const sampleAuthZPolicies = () => {
		return [
			{
				desc: 'Authorize static path.',
				expr: [ 'req.url.pathname == `/api/resources/`' ]
			},
			{
				desc: 'Authorize app provided resource.',
				expr: [
					'starts_with(req.url.pathname, `/api/resources/`)',
					'app.resources[? @ == $.req.url.pathsegs[2] ]'
				]
			},
			{
				desc: 'Authorize email',
				expr: [ 'user.email == `user@domain`' ]
			},
			{
				desc: 'Authorize group',
				expr: [ 'user.groups[? @ == `group:1`]' ]
			}
		];
	};

	const deleteAuthZ = async (authZId) => {
		debug('deleteAuthZ >', authZId);
		let url = `/api/authN/${props.authN.id}/authZ/${authZId}`;

		return fetch(url, {
			method: 'DELETE',
			credentials: 'include'
		})
		.then(response => response.json())
		.then(data => {
			debug('deleteAuthZ <', data);
			setAuthZs(authZs.filter(authZ => authZ.id != authZId));
		});
	};

	const handlePriorityChange = (from, to) => {
		debug('handlePriorityChange', from, to);
	};

	const authZElements = authZs?.map((authZ,i) => <AuthZ key={i} priority={i} handlePriorityChange={handlePriorityChange} authN={authN} authZs={authZs} authZ={authZ} />) || [];
	if(authZElements.length == 0) {
		authZElements.push(<AuthZ key='0' authN={authN} />);
	}

	const handleAddAuthZClick = (event) => {
		debug('handleAddAuthZClick:', event);
		setAuthZs(authZs.concat([{}]));
	};

	const handleDeleteAuthZClick = (event) => {
		debug('handleDeleteAuthZClick:', event);
		deleteAuthZ(authZs[authZIndex].id);
	};

	const handleAuthZChange = (event, value) => {
		debug('handleAuthZChange:', event, value);
		setAuthZIndex(parseInt(value) - 1);
	};

	return (
		<Box component='fieldset'>
			<legend>Authorization policies</legend>
			<Box sx={{ display: 'flex' }} alignItems='center'>
				<Pagination count={authZElements.length} siblingCount={0} showFirstButton showLastButton onChange={handleAuthZChange} />
				<Tooltip title='Delete current policy'>
					<IconButton
						size='small'
						edge='end'
						color='inherit'
						aria-label='Menu'
						onClick={handleDeleteAuthZClick}
					>
						<DeleteForeverIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title='Add new policy'>
					<IconButton
						size='small'
						edge='end'
						color='inherit'
						aria-label='Menu'
						onClick={handleAddAuthZClick}
					>
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Box>

			{authZElements[authZIndex]}
			{ showAuthZContext  
			? <Box>
				<Button variant='text' onClick={() => setShowAuthZContext(false)}>Hide authorization context</Button>
				<ReactJson src={sampleAuthZContext()} />
				<ReactJson src={sampleAuthZPolicies()} />
			  </Box> 
			: <Button variant='text' onClick={() => setShowAuthZContext(true)}>Show authorization context</Button>
			}
		</Box>
	);
}

