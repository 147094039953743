import Debug from 'debug';
import React, { useContext, useState, useEffect, useRef } from 'react';

import { AppBar, Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import {
	Menu as MenuIcon,
	DeleteForever as DeleteForeverIcon,
	KeyboardReturn as KeyboardReturnIcon
} from '@mui/icons-material';

import { AppContext } from './AppContext';

const debug = Debug('ezsso:admin:AuthZ');

export default function (props) {
	const { session, setSession } = useContext(AppContext);

	const [ id, setId ] = useState(props.authZ?.id);
	const [ statusCode, setStatusCode ] = useState(props.authZ?.statusCode);
	const [ description, setDescription ] = useState(props.authZ?.description);
	const [ priority, setPriority ] = useState(props.authZ?.priority);
	const [ access, setAccess ] = useState(props.authZ?.access || 'allow');

	const [ rules, setRules ] = useState(props.authZ?.rules);
	const [ rulesChanged, setRulesChanged ] = useState(false);

	const [ shouldStore, setShouldStore ] = useState(false);

	const createRule = async () => {
		setRules(rules.concat([{engine: 'jmespathts', expr: ''}]));
	};

	const handleStatusCodeChange = async (event) => {
		setStatusCode(event.target.value);
	};

	const handleStatusCodeBlur = async (event) => {
		setShouldStore(true);
	};

	const handleDescriptionChange = async (event) => {
		setDescription(event.target.value);
	};

	const handleDescriptionBlur = async (event) => {
		setShouldStore(true);
	};

	const handlePriorityChange = async (event) => {
		setPriority(event.target.value);
		setShouldStore(true);
	};

	const handleAccessChange = async (event) => {
		setAccess(event.target.value);
		setShouldStore(true);
	};

	const handleExprChange = async () => {
		setRulesChanged(true);
	};

	const handleExprBlur = async () => {
		if(!rulesChanged) {
			return;
		}

		const i = event.target.id.replace(/^expr:[^:]+:/,'');
		rules[i].expr = event.target.value;
	
		setRulesChanged(false);
		setShouldStore(true);
	};

	const store = async () => {

		const authZ = Object.assign({}, {
			id,
			statusCode,
			description,
			priority,
			access,
			rules
		});

		debug('store', authZ);
		let url = `/api/authN/${props.authN.id}/authZ`;
		if(id) {
			url += `/${id}`;
		}

		return fetch(url, {
						method: 'POST',
						credentials: 'include',
						headers: { 'Content-type': 'application/json' },
						body: JSON.stringify(authZ)
		})
		.then(response => response.json())
		.then(data => {
			debug('store', data);
			setId(data.id);
			setStatusCode(data.statusCode);
			setDescription(data.description);
			setRules(data.rules);
		});
	};

	const deleteRule = async (position) => {
		debug('deleteRule >', id, position);
		let url = `/api/authN/${props.authN.id}/authZ/${id}/rules/${position}`;

		return fetch(url, {
			method: 'DELETE',
			credentials: 'include',
			headers: { 'Content-type': 'application/json' }
		})
		.then(response => response.json())
		.then(data => {
			debug('deleteRule <', data);
			setRules(rules.filter((rule, i) => i != position));
		});
	};

	useEffect(() => {
		debug('useEffect', 'mounted');
		if(!id) {
			store();
		}
	}, []);

	useEffect(() => {
		if(!shouldStore) {
			return;
		}
		debug('useEffect', 'changed');
		store();
		setShouldStore(false);
	}, [shouldStore]);

	const ruleElements = rules?.map((rule, i) => {
				return <Box key={i}>
					<TextField
						id={'expr:'+id+':'+i}
						label='Expression'
						fullWidth
						size='small'
						sx={{ mt: '16px', mr: '4px' }}
						defaultValue={rule.expr} 
						autoComplete='authz_rule'
						onChange={handleExprChange}
						onBlur={handleExprBlur}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Tooltip title='Delete rule'>
										<DeleteForeverIcon onClick={() => deleteRule(i)} />
									</Tooltip>
								</InputAdornment>
							) 
						}}
					/>
				</Box>;
	}) || [];

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ display: 'inline-flex', width: '100%', mt: '16px' }}>

				<FormControl sx={{minWidth: '80px'}}>
					<InputLabel id='priority-label'>Priority</InputLabel>
					<Select
						labelId='priority-label'
						label='Priority'
						size='small'
						displayEmpty
						defaultValue={priority || 0}
						onChange={handlePriorityChange}
					>
						{(props.authZs || []).map((_authZ, i) => <MenuItem key={i} value={i}>{i+1}</MenuItem>)}
					</Select>
				</FormControl>

				<FormControl sx={{minWidth: '100px'}}>
					<InputLabel id='access-label'>Access</InputLabel>
					<Select
						labelId='access-label'
						label='Access'
						size='small'
						value={access || 'allow'}
						onChange={handleAccessChange}
					>
						<MenuItem value='allow'>Allow</MenuItem>
						<MenuItem value='deny'>Deny</MenuItem>
					</Select>
				</FormControl>

				<TextField
					label='Status Code'
					size='small'
					sx={{ mr: '4px' }}
					defaultValue={statusCode} 
					autoComplete='authz_statusCode'
					onChange={handleStatusCodeChange}
					onBlur={handleStatusCodeBlur}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip title='Save'>
									<KeyboardReturnIcon onClick={handleStatusCodeBlur} />
								</Tooltip>
							</InputAdornment>
						) 
					}}
				/>

				<TextField
					label='Description'
					size='small'
					fullWidth
					sx={{ mr: '4px' }}
					defaultValue={description} 
					autoComplete='authz_description'
					onChange={handleDescriptionChange}
					onBlur={handleDescriptionBlur}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip title='Save'>
									<KeyboardReturnIcon onClick={handleDescriptionBlur} />
								</Tooltip>
							</InputAdornment>
						) 
					}}
				/>
			</Box>
			<Button variant='text' onClick={createRule}>Add rule</Button>
			{ruleElements}
		</Box>
	);
}

