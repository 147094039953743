import Debug from 'debug';
import React, { useContext, useState, useEffect, useRef } from 'react';

import { AppBar, Avatar, Box, Button, FormControl, Icon, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import {
	Menu as MenuIcon,
	DeleteForever as DeleteForeverIcon,
	KeyboardReturn as KeyboardReturnIcon
} from '@mui/icons-material';

import ReactJson from 'react-json-view';

import { AppContext } from './AppContext';

import Loader from './Loader';
import OidcIdp from './OidcIdp';
import AppInfoEndpoint from './AppInfoEndpoint';
import AuthZs from './AuthZs';

const debug = Debug('ezsso:admin:AuthN');

export default function (props) {
	const {
		session, setSession,
		ezssoAdmin, setEzssoAdmin
	} = useContext(AppContext);

	const [ id, setId ] = useState(props.authN?.id);
	const [ cookieName, setCookieName ] = useState(props.authN?.cookie_name);
	const [ idpRedirectCode, setIDPRedirectCode ] = useState(props.authN?.idp_redirect_code);
	const [ status, setStatus ] = useState(props.authN?.status);
	const [ idps, setIdps ] = useState(props.authN?.idps);
	const [ appInfoEndpoints, setAppInfoEndpoints ] = useState(props.authN?.appinfo_endpoints);
	const [ authZs, setAuthZs ] = useState(props.authN?.authZ);

	const createAuthN = async () => {
		debug('createAuthN');
		return ezssoAdmin.authn.new()
		.then(data => {
			debug('createAuthN', data);
			setId(data.id);
			setCookieName(data.cookie_name);
			setIDPRedirectCode(data.idp_redirect_code);
			setStatus(data.status);
			setIdps(data.idps);
			setAuthZs(data.authZ);
		});
	};

	const deleteAuthN = async () => {
		debug('deleteAuthN');
		return ezssoAdmin.authn.del(id)
		.then(data => {
			debug('deleteAuthN', data);
		});
	};

	const handleExportAuthN = async () => {
		debug('handleExportAuthN');
		document.getElementById('download_iframe').src = `/api/authN/${id}/export`;
	};

	useEffect(() => {
		debug('mounted');
		if(!status) {
			createAuthN();
		}
	}, []);

	if(!id) {
		return <Loader />;
	}

	const handleStatusChange = (event) => {
		setStatus(event.target.value);
	};

	const handleIDPRedirectCodeChange = (event) => {
		setIDPRedirectCode(event.target.value);
	};

	const idpElements = idps?.map((idp,i) => <OidcIdp key={i} authN={{id}} idp={idp} />) || [];
	if(idpElements.length == 0) {
		idpElements.push(<OidcIdp key='0' authN={{id}} />);
	}

	const appInfoEndpointElements = appInfoEndpoints?.map((endpoint,i) => <AppInfoEndpoint key={i} authN={{id}} position={i} endpoint={endpoint} />) || [];
	if(appInfoEndpointElements.length == 0) {
		appInfoEndpointElements.push(<AppInfoEndpoint key='0' authN={{id}} />);
	}

	return (
		<Box>
			<iframe id='download_iframe' style={{ display: 'none' }}></iframe>
			<TextField
				id={'auth_id:'+id}
				label='Id'
				size='small'
				inputProps={{ style: { width: '350px', textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={id || ''}
				disabled={true}
				autoComplete='auth_id'
			/>
			<TextField
				id={'auth_cookie_name'+id}
				label='Cookie name'
				size='small'
				inputProps={{ style: { textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={cookieName || ''}
				disabled={true}
				autoComplete='auth_cookie_name'
			/>
			<TextField
				id={'auth_idp_redirect_code'+id}
				label='IDP Redirect code'
				size='small'
				inputProps={{ style: { textAlign: 'center' } }}
				sx={{ mt: '16px', mr: '4px' }}
				defaultValue={idpRedirectCode || ''}
				autoComplete='auth_idp_redirect_code'
				onChange={handleIDPRedirectCodeChange}
			/>
			<Box sx={{ display: 'inline-flex', width: '140px', mt: '16px' }}>
				<FormControl fullWidth={true}>
					<InputLabel id='status-label'>Status</InputLabel>
					<Select
						labelId='status-label'
						id={'status'+id}
						label='Status'
						size='small'
						value={status || ''}
						onChange={handleStatusChange}
					>
						<MenuItem value='available'>Available</MenuItem>
						<MenuItem value='unavailable'>Unavailable</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<Box>
				<Tooltip title='Delete authorizer'>
					<DeleteForeverIcon onClick={deleteAuthN} />
				</Tooltip>
			</Box>
			<Box>
				<Button variant='text'>Import authorizer</Button>
				<Button variant='text' onClick={handleExportAuthN}>Export authorizer</Button>
				<Button variant='text'>Setup clients</Button>
			</Box>
			<Box component='fieldset'>
				<legend>Identity Providers</legend>
				{idpElements}
			</Box>
			<Box component='fieldset'>
				<legend>App Info Endpoints</legend>
				{appInfoEndpointElements}
			</Box>
			<AuthZs authN={{id}} authZs={authZs} />
		</Box>
	);
}

